import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store';

import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB97YgFegnHsGhA2sH_-SckNk5rhDvy7e4",
  authDomain: "ino-0-1.firebaseapp.com",
  projectId: "ino-0-1",
  storageBucket: "ino-0-1.appspot.com",
  messagingSenderId: "384571762195",
  appId: "1:384571762195:web:48174210122f60502981e6",
  measurementId: "G-MX26RJM6ZY"
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp)
const storage = getStorage(firebaseApp)

const app = createApp(App)

app.use(router)
app.use(store);
app.mount('#app')

export { 
  db,
  storage
}