import { createStore } from 'vuex';

export default createStore({
  state: {
    isPlaying: false,
    currentTrack: null,
    currentAlbum: null,
    player: new Audio(),
    currentTime: 0,
    isMinimized: false,
  },
  mutations: {
    setPlaying(state, isPlaying) {
      state.isPlaying = isPlaying;
    },
    setCurrentTrack(state, track) {
      state.currentTrack = track;
    },
    setCurrentAlbum(state, album) {
      state.currentAlbum = album;
    },
    setCurrentTime(state, time) {
      state.currentTime = time;
    },
    setMinimized(state, isMinimized) {
      state.isMinimized = isMinimized;
    },
  },
  actions: {
    playTrack({ commit, state }, { track, album }) {
      state.player.src = track.trackUrl;
      state.player.play();
      commit('setPlaying', true);
      commit('setCurrentTrack', track);
      commit('setCurrentAlbum', album);
    },
    pauseTrack({ commit, state }) {
      state.player.pause();
      commit('setPlaying', false);
    },
    updateTime({ commit, state }) {
      commit('setCurrentTime', state.player.currentTime);
    },
    seek({ commit, state }, time) {
      state.player.currentTime = time;
      commit('setCurrentTime', time);
    },
    minimizePlayer({ commit }, isMinimized) {
      commit('setMinimized', isMinimized);
    },
  },
});