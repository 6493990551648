<template>
  <router-view />
  <div v-if="currentTrack" :class="{'minimized-player': isMinimized}">
    <div class="controllers-wrapper">
      <div class="currently-listening">
        <span v-if="currentTrack && currentAlbum"><b>♪ Reproduciendo: </b>
          <marquee>✟ {{ currentTrack.trackTitle }} - {{ currentAlbum.artist }} ✟</marquee>
        </span>
      </div>

      <div class="album-cover-wrapper">
        <div>
          <a href="">
            <img class="album-cover" :src="currentAlbum.cover">
          </a>
        </div>
      </div>

      <div class="progress-bar">
        <input type="range" min="0" :max="player.duration" v-model="currentTime" @input="seek">
      </div>

      <div class="controllers">
        <span class="prev" @click="prev"><i class="large material-icons">skip_previous</i></span>
        <span class="play" v-if="!isPlaying" @click="play()"><i class="large material-icons">play_arrow</i></span>
        <span class="pause" v-else @click="pauseSong()"><i class="large material-icons">pause</i></span>
        <span class="next" @click="next"><i class="large material-icons">skip_next</i></span>
      </div>
      <button @click="toggleMinimize">{{ isMinimized ? 'Expand' : 'Minimize' }}</button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState(['isPlaying', 'currentTrack', 'currentAlbum', 'player', 'currentTime', 'isMinimized']),
  },
  methods: {
    ...mapActions(['playTrack', 'pauseTrack', 'seek', 'minimizePlayer']),
    prev() {
      this.$store.dispatch('prev');
    },
    next() {
      this.$store.dispatch('next');
    },
    toggleMinimize() {
      this.minimizePlayer(!this.isMinimized);
    },
  },
};
</script>

<style>
body {
  background-color: black;
}

.controllers-wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #000;
  color: #00ff00;
  font-family: 'Courier New', Courier, monospace;
  border-top: 1px solid #00ff00;
  padding: 10px;
}

.minimized-player .controllers-wrapper {
  height: 50px;
  overflow: hidden;
}

.currently-listening {
  background-color: #00ff00;
  color: #000;
}

.album-cover-wrapper {
  text-align: center;
}

.album-cover {
  width: 100px;
}

.progress-bar {
  width: 100%;
  margin-top: 10px;
}

.progress-bar input[type="range"] {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  height: 10px;
  background: linear-gradient(to right, #00ff00, #000);
  border-radius: 5px;
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
}

.progress-bar input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #00ff00;
  cursor: pointer;
  border-radius: 50%;
}

.progress-bar input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #00ff00;
  cursor: pointer;
  border-radius: 50%;
}

.controllers {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.controllers i {
  font-size: 30px !important;
  color: #00ff00;
}
</style>